import axios from 'axios';

export const getIpsAddress = async () => {
    try {
        let config1 = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api-bdc.net/data/ip-geolocation?localityLanguage=en&key=bdc_d078bfe1d5694822baea54a199497435',
            headers: {}
        };

        let config2 = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api.bigdatacloud.net/data/client-info',
            headers: {}
        };

        const [response1, response2] = await Promise.all([
            axios.request(config1),
            axios.request(config2)
        ]);
        const details = {
            ipDetails: response1.data,
            deviceInfo: response2.data
        }

        return details;
    } catch (error) {
        console.log(error);
        return []; // or handle the error as needed
    }
};
